import { likeRegExp } from '@/offline/database'

export default {
  async _addSubqueriesOrdenTrabajoSubsis (Vue, rows) {
    const tables = Vue.$offline.db.tables
    for (let row of rows) {
      row.cantMaterialAfectado = (await Vue.$offline.db
        .select(Vue.$offline.db.fn.count(tables.orden_trabajo_matsist.idorden_trabajo_matsist).as('count'))
        .from(tables.orden_trabajo_matsist)
        .innerJoin(
          tables.material_sistema,
          tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.orden_trabajo_matsist.idorden_trabajo.eq(row.orden_trabajo.idorden_trabajo),
            tables.material_sistema.idsubsis.eq(row.subsis.idsubsis),
            tables.orden_trabajo_matsist.estado.gt(0),
          )
        )
        .exec()
      )[0].count
      row.cantMaterialInstalar = (await Vue.$offline.db
        .select(Vue.$offline.db.fn.count(tables.lorden_trabajo.idlorden_trabajo).as('count'))
        .from(tables.lorden_trabajo)
        .where(
          Vue.$offline.db.op.and(
            tables.lorden_trabajo.idorden_trabajo.eq(row.orden_trabajo.idorden_trabajo),
            tables.lorden_trabajo.idsubsis.eq(row.subsis.idsubsis),
            tables.lorden_trabajo.estado.gt(0),
          )
        )
        .exec()
      )[0].count
      row.cantChecklistSubsis = (await Vue.$offline.db
        .select(Vue.$offline.db.fn.count(tables.checklist_ot_subsis.idchecklist_ot_subsis).as('count'))
        .from(tables.checklist_ot_subsis)
        .innerJoin(
          tables.checklist_ot,
          tables.checklist_ot_subsis.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.checklist_ot.idorden_trabajo.eq(row.orden_trabajo.idorden_trabajo),
            tables.checklist_ot_subsis.idsubsis.eq(row.subsis.idsubsis),
            tables.checklist_ot_subsis.estado.gt(0),
          )
        )
        .exec()
      )[0].count
    }
    return rows
  },
  _selectOrdenTrabajoSubsisBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.orden_trabajo_subsis)
      .innerJoin(
        tables.orden_trabajo,
        tables.orden_trabajo_subsis.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .orderBy(tables.subsis.orden)
  },
  async selectOrdenTrabajoSubsis (Vue, filter, search, sorter, page, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.subsis.codigo.match(reQ),
          tables.subsis.descripcion.match(reQ),
          tables.tsubsis.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.orden_trabajo_subsis.idorden_trabajo.eq(idordenTrabajo),
        tables.orden_trabajo_subsis.estado.gt(0),
        tables.subsis.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows = await this._selectOrdenTrabajoSubsisBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .exec()
    return [
      await this._addSubqueriesOrdenTrabajoSubsis(Vue, rows),
      []
    ]
  },
  async selectOrdenTrabajoSubsisRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectOrdenTrabajoSubsisBase(Vue)
      .where(tables.orden_trabajo_subsis.idorden_trabajo_subsis.in(pks))
      .exec()
    return await this._addSubqueriesOrdenTrabajoSubsis(Vue, rows)
  },
  async deleteOrdenTrabajoSubsis (Vue, idordenTrabajoSubsis) {
    const ordenTrabajoSubsis = await Vue.$offline.ordenTrabajoSubsis.row(idordenTrabajoSubsis)
    await Vue.$offline.ordenTrabajoSubsis.deleteSync(idordenTrabajoSubsis)
    await Vue.$offline.checklistOt.borrarPreguntasSubsis(
      ordenTrabajoSubsis.orden_trabajo_subsis.idorden_trabajo,
      ordenTrabajoSubsis.orden_trabajo_subsis.idsubsis
    )
  },
}
